import RxPageLayout from "@/components/ui/display/RxPageLayout";
import React, { FunctionComponent } from "react";
import { useRouter } from "next/router";

import { colours } from "utils";
import * as UserContext from "../components/UserContext";
import ClientRender from "../components/ClientRender";
import CtaStrip from "components/marketing/CtaStrip";
import MeetTheCeoContent from "components/marketing/home/MeetTheCeoContent";
import QuoteContent from "components/marketing/QuoteContent";
import AllBrightPillarsContent from "components/marketing/home/AllBrightPillarsContent";
import ProfitabilityContent from "components/marketing/home/ProfitabilityContent";
import AsSeenInContent from "components/marketing/home/AsSeenInContent";
import TheBrightPostContent from "components/marketing/home/TheBrightPostContent";
import RxHeaderCarousel from "components/ui/display/RxHeaderCarousel";
import { quoteInTodaysWorld } from "@/utils/pageContents";
import HeaderSlideContent from "@/types/marketing/HeaderSlideContent";
import { Article } from "@/types/article/Article";
import RedefiningAmbitionContent from "@/components/marketing/home/RedefiningAmbitionContent";
import InvestContent from "@/components/marketing/home/InvestContent";
import ArticleClient from "@/client/ArticleClient";
import { getHeaderSlides } from "@/components/hooks/query/useContentQuery";
import HeaderSlide from "@/components/marketing/home/HeaderSlide";
import WooboxQuizContent from "@/components/marketing/WooboxQuizContent";
import Modal from "@/components/Modal";
import SessionsHouseModalContent from "@/components/old-sessions-house/SessionsHouseModalContent";

type IndexProps = {
  headerCollection: HeaderSlideContent[];
  articles: Article[];
};

const Index: FunctionComponent<IndexProps> = (props) => {
  const user = React.useContext(UserContext.Context);
  const router = useRouter();
  const headerSlides = getHeaderSlides();

  const slidesLoading = headerSlides.isLoading;

  const [modalOpen, setModalOpen] = React.useState(true);

  React.useEffect(() => {
    if (user.id) router.push("/home");
  }, [user.id]);

  return (
    <ClientRender>
      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          colour={colours.shellGuideline}
        >
          <SessionsHouseModalContent />
        </Modal>
      )}
      <RxPageLayout>
        <RxHeaderCarousel>
          {!slidesLoading &&
            headerSlides.data.map((slide) => (
              <HeaderSlide key={slide.title} {...slide} />
            ))}
        </RxHeaderCarousel>
        <RedefiningAmbitionContent />
        <InvestContent />
        <WooboxQuizContent type="persona" />
        <AllBrightPillarsContent />
        <CtaStrip
          backgroundPattern="yellow"
          copy="Become a member"
          linkText="Join AllBright"
          linkUrl="/memberships"
          textColour={colours.charcoal}
        />
        <MeetTheCeoContent />
        <QuoteContent {...quoteInTodaysWorld} />
        <CtaStrip
          backgroundColour={colours.marketingYellow}
          copy="Become a member"
          linkText="Join AllBright"
          linkUrl="/memberships"
          textColour={colours.charcoal}
        />
        <ProfitabilityContent />
        <AsSeenInContent />
        <TheBrightPostContent articles={props.articles} />
        <CtaStrip
          backgroundColour={colours.marketingYellow}
          copy="Become a member"
          linkText="Join AllBright"
          linkUrl="/memberships"
          textColour={colours.charcoal}
        />
      </RxPageLayout>
    </ClientRender>
  );
};

export const getStaticProps = async () => {
  const articleClient = new ArticleClient();

  return {
    props: {
      headerCollection: [],
      articles: await articleClient.getArticlesByIds(
        ArticleClient.getStaticHomepageArticleIds()
      ),
    },
    revalidate: 86400,
  };
};

export default Index;
