import styled from "styled-components";
import { H2, CXL } from "../Typography";
import RxButton from "../ui/inputs/RxButton";
import { useRouter } from "next/router";
import Image from "next/image";
import { colours } from "@/utils";

const SessionsHouseModalContent = () => {
  const router = useRouter();

  return (
    <S.Container>
      <S.Row>
        <H2>
          AllBright<S.FontWeight>@</S.FontWeight> <br />
          <S.RoxboroughFontWrap>
            Ol<S.Italic>d</S.Italic> Sessions H<S.Italic>o</S.Italic>use
          </S.RoxboroughFontWrap>
        </H2>
        <S.ImageContainer>
          <Image
            src="/images/old-sessions-house/interior-chairs.jpg"
            layout="fill"
            objectFit="cover"
          />
        </S.ImageContainer>
      </S.Row>
      <S.Row>
        <S.ImageContainer>
          <Image
            src="/images/old-sessions-house/cafe.jpg"
            layout="fill"
            objectFit="cover"
          />
        </S.ImageContainer>
        <CXL weight={500}>
          Welcome to our next chapter and our new home for connection, learning
          and inspiration.
        </CXL>
      </S.Row>
      <RxButton onClick={() => router.push("/old-sessions-house")}>
        Find out more
      </RxButton>
    </S.Container>
  );
};

const S = () => {};

S.Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

S.Row = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

S.ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  height: 100%;
  width: 40%;
  aspect-ratio: 1.5/1;
  flex-shrink: 0;
  box-shadow: 0 0 5px ${colours.shellOverlay};

  @supports not (aspect-ratio: 1.5/1) {
    &:before {
      float: left;
      padding-top: 66%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.FontWeight = styled.span`
  font-weight: 400;
  font-style: italic;
`;

S.Italic = styled.span`
  font-style: italic;
`;

export default SessionsHouseModalContent;
